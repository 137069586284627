<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Kiracı Ekle</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12">
          <v-col sm="6" md="4" v-if="hasRenter">
            <rs-select
              :items="oldRenterTypeList"
              :item-text="(item) => $t(item.translation_key)"
              label="Eski Kiracı Durumu"
              :rules="[rules.required]"
              v-model="formData.old_house_user_status_id"
              required
            />
          </v-col>
          <v-col sm="6" md="4" v-if="hasRenter">
            <rs-datepicker
              label="Eski Kiracı Çıkış Tarihi"
              v-model="formData.old_owner_left_date"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="6" md="4">
            <rs-select
              :items="newRenterTypeList"
              :item-text="(item) => $t(item.translation_key)"
              label="Yeni Kiracı Durumu"
              :rules="[rules.required]"
              v-model="formData.new_renter_type_id"
              required
            />
          </v-col>
        </v-row>
        <v-row dense v-if="formData.new_renter_type_id === 2">
          <v-col sm="6" md="4">
            <rs-datepicker
              :label="$t('labels.new_renter_date')"
              v-model="formData.new_owner_date"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
            />
          </v-col>

          <v-col sm="6" md="4">
            <rs-select-resident
              label="Yeni Kiracı"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="house.block.cluster_id"
              :readonly="disabled"
              :filled="disabled"
              :extra-params="residentSearchParams"
              :clearable="!disabled"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="formData.new_renter_type_id === 1">
          <v-col cols="12">
            <v-divider class="my-6" />

            <h5>Yeni Kiracı Bilgileri</h5>
          </v-col>

          <v-col cols="6">
            <rs-datepicker
              :label="$t('labels.new_renter_date')"
              v-model="formData.new_owner_date"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-text-field
              :label="$t('labels.name_surname')"
              v-model="formData.name"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-text-field
              type="email"
              :label="$t('labels.email')"
              v-model="formData.email"
              :rules="[
                isRequired('email') ? rules.required : true,
                rules.email,
              ]"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-tel-field
              :label="$t('labels.mobile_phone')"
              :rules="[
                isRequired('phone') ? rules.required : true,
                formData.phone != ''
                  ? rules.minLength(formData.phone, 7)
                  : null,
                rules.numbersOnlyWithParam,
              ]"
              v-model="formData.phone"
              :disabled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-text-field
              :label="$t('labels.car_plate_number')"
              v-model="formData.plate_number"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-select
              label="Vatandaşlık"
              v-model="formData.citizenship_id"
              :rules="[isRequired('citizenship_id') ? rules.required : true]"
              :items="countryList"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              class="identity-number"
              :label="$t('labels.identity_no')"
              v-model="formData.identity_number"
              :rules="[
                rules.exactLength(formData.identity_number, 11),
                rules.numbersOnly,
                isRequired('identity') && formData.citizenship_id === 230
                  ? rules.required
                  : true,
              ]"
              maxlength="11"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              label="Pasaport Numarası"
              v-model="formData.passport_no"
              :rules="[
                rules.maxLength(formData.passport_no, 150),
                isRequired('identity') && formData.citizenship_id !== 230
                  ? rules.required
                  : true,
              ]"
              maxlength="150"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="6">
            <rs-text-field
              type="password"
              :label="$t('labels.password')"
              v-model="formData.password"
              readonly
              filled
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { oldRenterTypeList, newRenterTypeList } from "@/core/data/options";
import { hasForm } from "@/view/mixins";

export default {
  name: "RenterForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["clusterId", "cluster", "countryList"]),
    hasRenter() {
      if (
        !this.houseData ||
        !this.houseData.house_users ||
        !this.houseData.house_users.length
      ) {
        return false;
      }

      return (
        this.houseData.house_users.find((v) => v.house_user_type_id === 2) !==
        undefined
      );
    },
    residentSearchParams() {
      return {};
      // if (
      //   this.houseUserId &&
      //   this.originalData.id === parseInt(this.houseUserId)
      // ) {
      //   return {};
      // }

      // return { is_active: true };
    },
  },
  data() {
    return {
      isLoading: false,
      formData: {
        old_house_user_status_id: null,
        new_renter_type_id: 1,
        house_id: null,
        resident_id: null,
        is_spokesperson: true,
        is_staying: true,
        password: null,
        plate_number: null,
        email: null,
        phone: null,
        name: null,
        new_owner_date: null,
        house_user_type_id: 2,
        citizenship_id: null,
        identity_number: null,
        passport_no: null,
        old_owner_left_date: null,
      },
      oldRenterTypeList,
      newRenterTypeList,
      houseData: {},
    };
  },
  methods: {
    show(houseUserId, extraParams) {
      this.houseUserId = null;
      this.houseData = {};

      this.formData = {
        old_house_user_status_id: 2,
        new_renter_type_id: 1,
        house_id: null,
        resident_id: null,
        share: null,
        is_spokesperson: true,
        is_staying: true,
        password: null,
        plate_number: null,
        email: null,
        phone: null,
        name: null,
        new_owner_date: null,
        house_user_type_id: 2,
        citizenship_id: null,
        identity_number: null,
        passport_no: null,
        old_owner_left_date: null,
      };

      if (houseUserId) {
        this.houseUserId = houseUserId;
        this.load();
      }

      if (extraParams) {
        if (extraParams.house_id) {
          this.formData.house_id = extraParams.house_id;
        }
      }

      this.loadHouse();

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.formData = {};
      this.hide();
    },
    loadHouse() {
      this.isLoading = true;

      this.$api
        .get("houses", this.house.id)
        .then((response) => {
          this.houseData = response.data.data;
        })
        .catch(() => {
          this.$toast.error("Bağımsız bölüm bilgileri yüklenemedi.");
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      let formData;

      if (this.formData.new_renter_type_id !== 3) {
        if (this.formData.resident_id) {
          formData = {
            resident_id: this.formData.resident_id,
            house_id: this.formData.house_id,
            old_house_user_status_id: this.formData.old_house_user_status_id,
            house_user_type_id: this.formData.house_user_type_id,
            old_owner_left_date: this.formData.old_owner_left_date,
            new_owner_date: this.formData.new_owner_date,
          };
        } else {
          formData = {
            house_id: this.formData.house_id,
            old_house_user_status_id: this.formData.old_house_user_status_id,
            is_spokesperson: this.formData.is_spokesperson,
            is_staying: this.formData.is_staying,
            name: this.formData.name,
            email: this.formData.email,
            phone: this.formData.phone,
            plate_number: this.formData.plate_number,
            new_owner_date: this.formData.new_owner_date,
            house_user_type_id: this.formData.house_user_type_id,
            citizenship_id: this.formData.citizenship_id,
            identity_number: this.formData.identity_number,
            passport_no: this.formData.passport_no,
            old_owner_left_date: this.formData.old_owner_left_date,
          };

          if (formData.phone) {
            formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
          }
        }
      } else {
        formData = {
          no_renter: true,
          house_id: this.formData.house_id,
          old_house_user_status_id: this.formData.old_house_user_status_id,
          house_user_type_id: this.formData.house_user_type_id,
        };
      }

      this.$api
        .post(`clusters/${this.clusterId}/house-users`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.handleCancelClick();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    isRequired(fieldName) {
      if (
        !this.cluster ||
        !this.cluster.resident_required_fields ||
        !this.cluster.resident_required_fields.length
      ) {
        return false;
      }

      return this.cluster.resident_required_fields.indexOf(fieldName) !== -1;
    },
  },
};
</script>
