<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bağımsız Bölüm Açılış Alacak Fişi</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-text-field
              :label="$t('labels.date')"
              :value="receiptDate && receiptDate.toLocaleDateString()"
              required
              readonly
              filled
              @change="generateDescription"
            />
          </v-col>
          <v-col sm="6">
            <v-select
              :items="collectingTypeList"
              item-value="id"
              item-text="name"
              :label="$t('labels.opening_receipt_type')"
              :rules="[rules.required]"
              v-model="formData.collecting_type_id"
              hide-details="auto"
              required
              @change="generateDescription"
            ></v-select>
          </v-col>
          <v-col sm="6">
            <rs-select-resident
              label="Alacaklı Kişi"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? houseId : null"
              :readonly="disabled"
              :filled="disabled"
              required
            />
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
              @change="formData.resident_id = null"
            ></v-switch>
          </v-col>
          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :label="$t('labels.opening_receipt_amount')"
              :rules="
                disabled
                  ? []
                  : [
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 9999999.99),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              :label="$t('labels.description')"
              :rules="[rules.required]"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "OpenningDueReceiptForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["collectingTypeList", "cluster", "clusterId"]),
  },
  data() {
    return {
      onlyHouseUsers: true,
      receiptDate: null,
      houseId: null,
      formData: {
        collecting_type_id: null,
        resident_id: null,
        amount: null,
        description: null,
      },
    };
  },
  methods: {
    show(houseId) {
      const legalBeginningDate = new Date(this.cluster.legal_beginning_date);

      const date = new Date();
      date.setHours(12);
      date.setFullYear(legalBeginningDate.getFullYear());
      date.setMonth(legalBeginningDate.getMonth());
      date.setDate(legalBeginningDate.getDate() - 1);
      this.receiptDate = date;

      if (!houseId) {
        return window.alert("Bağımsız bölüm alanı gereklidir.");
      }

      this.houseId = houseId;

      this.$refs.sidePanel.show();
    },
    handleFormSubmit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`income/houses/${this.houseId}/first-balance`, this.formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.formData = {};
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.formData = {};
      this.hide();
    },
    generateDescription() {
      let description = "";

      if (this.formData.collecting_type_id) {
        for (const index in this.collectingTypeList) {
          if (
            this.collectingTypeList[index].id ===
            this.formData.collecting_type_id
          ) {
            description = "[DEVİR] ";
            description += " [" + this.collectingTypeList[index].name + " - ";
            description +=
              new Date(this.receiptDate).toLocaleDateString() + "] ";
            break;
          }
        }
      }

      this.formData.description = description;
    },
  },
};
</script>
